import { UitkSecondaryButton } from "@egds/react-core/button";
import * as React from "react";
import { AnalyticsStore } from "bernie-plugin-mobx";
import { HotelsFlexModuleResult } from "typings/microserviceModels/hotels-flex-module";
import { inject } from "mobx-react";
import { AnalyticsConfig } from "@egds/react-core/utils";
import { useLocalization } from "@shared-ui/localization-context";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";
import { UitkText } from "@egds/react-core/text";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

export const ViewAllHotelsButton = inject(
  "context",
  "analytics"
)(
  ({
    model,
    context,
    analytics,
  }: {
    model: HotelsFlexModuleResult;
    context?: ExtendedContextStore;
    analytics?: AnalyticsStore;
  }) => {
    /* istanbul ignore next line */
    const analyticsConfig: AnalyticsConfig = {
      id: "TG.LP.Dest.Hotels.SeeAll",
      callback: (id, description) => {
        const flexTrackingInfo: FlexTrackingInfo = {
          moduleName: id,
          action: Action.CLICK,
          linkName: description,
        };
        sendDelayedTrackEvent(flexTrackingInfo, analytics);
      },
    };
    const lowerTotalHotelLimit = 1;
    const { formatText } = useLocalization();
    const language = context?.locale.split(/[_-]/);
    const localeWithFallbacks = language?.length ? [language.join("-"), ...language] : [];
    const buttonText =
      model.totalHotels > lowerTotalHotelLimit
        ? formatText(
            "hotels.seeAllHotelsWithNumber",
            model.totalHotels,
            model.totalHotels.toLocaleString(localeWithFallbacks),
            model.locationName
          )
        : formatText("hotels.seeAllHotels", model.locationName);

    if (!model.seeAllLink || !model.locationName) {
      return null;
    }
    return (
      <UitkText align="center">
        <UitkSecondaryButton href={model.seeAllLink} tag="a" analytics={analyticsConfig}>
          {buttonText}
        </UitkSecondaryButton>
      </UitkText>
    );
  }
);
