import { UitkCardContentSection } from "@egds/react-core/cards";
import { CardContentReviewsPriceProposition } from "./CardContentReviewsPriceProposition";
import * as React from "react";
import { Hotel, HotelsFlexModuleResult } from "src/typings/microserviceModels/hotels-flex-module";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

export const CardContent = ({
  hotel,
  model,
  context,
  locale,
  isViewCarousel,
}: {
  hotel: Hotel;
  model: HotelsFlexModuleResult;
  context: ExtendedContextStore;
  locale: string;
  isViewCarousel?: boolean;
}) => {
  return (
    <UitkCardContentSection className="first">
      <CardContentReviewsPriceProposition
        hotel={hotel}
        context={context}
        locale={locale}
        locationName={model.locationName}
        showDistance={model.showDistance}
        showAddress={model.showAddress}
        isViewCarousel={isViewCarousel}
        shouldUseImperialDistanceUnits={model.shouldUseImperialDistanceUnits}
        addressDistanceView={model.addressDistanceView}
      />
    </UitkCardContentSection>
  );
};
