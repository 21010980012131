// External Libraries
import * as React from "react";
import { Viewport, ViewSmall, ViewLarge } from "@shared-ui/viewport-context";
import { inject, observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";
import {
  EgClickstreamImpressionTracker,
  EgClickstreamImpressionTrackerProps,
} from "@shared-ui/clickstream-analytics-context";

// UITK
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkHeading } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";
import { AnalyticsConfig } from "@egds/react-core/utils";

// Components
import { HotelCard } from "../components/HotelCard";
import { ViewAllHotelsButton } from "../components/ViewAllHotelsButton";
import { CardContent } from "../components/CardContent";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";
import { hotelStructuredData } from "components/flexComponents/Hotels/utils/getHotelStructuredData";
import { SchemaOrgScript } from "components/utility/SchemaOrg/SchemaOrgScript";
import { LegalText } from "../components/LegalText";
import { HotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";
import IsomorphicCarousel from "src/components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { useMapPropertySearchById } from "src/components/flexComponents/Hotels/hooks/useMapPropertySearchById";
import { withStores } from "src/stores";
import { EG_RECS_STRATEGY } from "components/flexComponents/Hotels/utils/constants";
import { egRecsClickstreamPresentedEvent } from "components/flexComponents/Hotels/utils/egRecsEvents";

// Types
import { HotelsProps, ViewCarouselQueryComponentProps, HotelCardCarouselProps } from "../typings";
import { Hotel, HotelsFlexModuleResult } from "typings/microserviceModels/hotels-flex-module";
import { getFmId } from "src/stores/ExperienceTemplateStore";

const ViewCarouselComponent: React.FC<HotelsProps> = (props: HotelsProps) => {
  const { templateComponent, context, flexModuleModelStore } = props;

  if (!templateComponent) {
    return null;
  }

  const {
    metadata: { id },
  } = templateComponent;
  const model = flexModuleModelStore.getModel(id) as HotelsFlexModuleResult | null;

  if (!model || model.hotels?.length === 0) {
    return null;
  }

  return (
    <ViewCarouselQueryComponent
      templateComponent={templateComponent}
      context={context}
      model={model}
      hotels={props.hotels}
    />
  );
};

const ViewCarouselQueryComponent = (props: ViewCarouselQueryComponentProps) => {
  const { templateComponent, context, model, hotels: hotelsStore } = props;
  const {
    metadata: { id },
  } = templateComponent;

  const fmId = getFmId(templateComponent);

  const isEgRecsStrategy = Boolean(EG_RECS_STRATEGY.includes(model.strategy));

  useMapPropertySearchById(model, hotelsStore, context);

  const hotelCardProps = (hotel: Hotel) => ({
    callToActionHotelName: model.callToActionHotelName,
    context,
    hotel,
    CardContent: cardContent(hotel),
    isMobile: true,
    showGuestRating: model.showGuestRating,
    locationName: model.locationName,
  });

  const cardContent = (hotel: Hotel) => () => (
    <CardContent model={model} hotel={hotel} context={context} locale={context.locale} isViewCarousel />
  );

  const HotelsWrapper = isEgRecsStrategy ? EgClickstreamImpressionTracker : React.Fragment;
  const hotelsWrapperProps = isEgRecsStrategy
    ? { eventData: egRecsClickstreamPresentedEvent(model), trackOnce: true }
    : ({} as EgClickstreamImpressionTrackerProps);

  return (
    <LazyLoad context={context}>
      <HotelsContext.Provider value={{ context, templateComponent, model }}>
        <SchemaOrgScript structuredData={hotelStructuredData(model.hotels)} />
        <Viewport>
          <ViewSmall>
            <UitkSpacing margin={{ blockend: "six" }}>
              <div className="Hotels Hotels_ViewCarousel" id={id} data-fm={fmId}>
                <HotelsWrapper {...hotelsWrapperProps}>
                  <HotelCardCarousel model={model} hotelCardProps={hotelCardProps} />
                  <ViewAllHotelsButton model={model} />
                  <UitkSpacing padding={{ blockstart: "three", inline: "three" }}>
                    <LegalText />
                  </UitkSpacing>
                </HotelsWrapper>
              </div>
            </UitkSpacing>
          </ViewSmall>
          <ViewLarge>
            <UitkSpacing margin={{ blockend: "six" }}>
              <div className="Hotels" id={id} data-fm={fmId}>
                <HotelsWrapper {...hotelsWrapperProps}>
                  <UitkHeading tag="h3" size={4}>
                    {model.title}
                  </UitkHeading>
                  <UitkSpacing margin={{ blockend: "three" }}>
                    <UitkLayoutGrid columns={3} space="three">
                      {model.hotels.map((hotel, hotelIndex) => (
                        <UitkLayoutGridItem key={`hotel-carousel-view-${hotel.hotelId}`}>
                          <HotelCard
                            {...hotelCardProps(hotel)}
                            index={hotelIndex + 1}
                            locationId={model.destinationId}
                            isViewCarousel={true}
                          />
                        </UitkLayoutGridItem>
                      ))}
                    </UitkLayoutGrid>
                  </UitkSpacing>
                  <ViewAllHotelsButton model={model} />
                  <UitkSpacing padding={{ blockstart: "three" }}>
                    <LegalText />
                  </UitkSpacing>
                </HotelsWrapper>
              </div>
            </UitkSpacing>
          </ViewLarge>
        </Viewport>
      </HotelsContext.Provider>
    </LazyLoad>
  );
};

// To be replaced by a more generic hotel card carousel
const HotelCardCarousel = inject("analytics")((props: HotelCardCarouselProps) => {
  const { analytics, model, hotelCardProps } = props;
  const { formatText } = useLocalization();

  const hotelAnalyticsId = "TG.LP.Dest.Hotels";
  /* istanbul ignore next */
  const scrolledPastSecondHotel = (moduleName: string) => {
    const hotelAnalyticsModuleName = `${hotelAnalyticsId}.Hotels.Carousel.itemViewed`;
    const firstHotelModuleName = `${hotelAnalyticsModuleName}.0`;
    const secondHotelModuleName = `${hotelAnalyticsModuleName}.1`;

    return moduleName !== firstHotelModuleName && moduleName !== secondHotelModuleName;
  };
  /* istanbul ignore next */
  const analyticsConfig: AnalyticsConfig = {
    id: hotelAnalyticsId,
    callback: (moduleId, description) => {
      const flexTrackingInfo: FlexTrackingInfo = {
        moduleName: moduleId,
        action: Action.SCROLL,
        linkName: description,
      };
      if (scrolledPastSecondHotel(moduleId)) {
        sendDelayedTrackEvent(flexTrackingInfo, analytics);
      }
    },
  };

  return (
    <UitkSpacing padding={{ blockend: "three" }}>
      <div>
        <UitkHeading tag="h3" size={4}>
          {model.title}
        </UitkHeading>
        <IsomorphicCarousel
          analytics={analyticsConfig}
          carouselName="Hotels"
          itemsVisible={{ lg: 1, md: 1, sm: 1 }}
          pageBy={1}
          peek
          buttonText={{
            nextButton: formatText("carousel.item.next"),
            prevButton: formatText("carousel.item.prev"),
          }}
        >
          {model.hotels.map((hotel, hotelIndex) => (
            <HotelCard
              {...hotelCardProps(hotel)}
              key={`hotel-card-carousel-${hotel.hotelId}`}
              index={hotelIndex + 1}
              locationId={model.destinationId}
              isViewCarousel={true}
            />
          ))}
        </IsomorphicCarousel>
      </div>
    </UitkSpacing>
  );
});

export const ViewCarousel = withStores("hotels", "flexModuleModelStore", "context")(observer(ViewCarouselComponent));

export default ViewCarousel;
